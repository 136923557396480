.td-title{
  text-align: center;
}
.main-content{
  /* background-color: red; */
  padding: 30px;
  box-shadow: 0 1px 5px 5px rgba(156,156,156,.07);
}
.form-control:focus, .form-select:focus {
  border-color: #ffe500 !important;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px #ffe600ab !important;
}
.chip{
  background-color: #ffe500 !important;
  color: #121826 !important;
}
.sub-text{
  /* color: red; */
  vertical-align: 10px;
}
.bottom-text p{
  font-size: 12px;
}